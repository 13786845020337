import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// import SearchIcon from '@mui/icons-material/Search';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Link from 'next/link';
import { withRouter } from 'next/router';

import { compose } from 'redux';
import { connect } from 'react-redux';
import createSlug from '../../../utils/createSlug';

const pages = [
  { name: 'Sälja', link: '/salja' }, //sell
  { name: 'Till Salu', link: '/till-salu' }, //for sale
  { name: 'Köpa', link: '/kopa' },
  { name: 'Om oss', link: '/om-oss' },
];

const Navbar = (props) => {
  const { asPath } = props.router;

  const [markPos, setMarkPos] = React.useState(-2);

  React.useEffect(() => {
    if (asPath === '/') setMarkPos(-1);
    else if (asPath.includes('salja')) setMarkPos(0);
    else if (asPath.includes('kopa')) setMarkPos(2);
    else if (asPath.includes('om-oss')) setMarkPos(3);
    else if (asPath.includes('till-salu')) setMarkPos(1);
    else {
      let flag = false;
      props.houseTypes.forEach((item) => {
        if (asPath.includes(createSlug(item.Type))) {
          flag = true;
        }
      });
      setMarkPos(flag ? 1 : -2);
    }
  }, [asPath]);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <AppBar
      position="static"
      sx={{
        height: { xs: '64px', lg: '84px' },
        width: '100%',
      }}
    >
      <Box
        sx={{
          // paddingLeft: { xs: '27px', lg: '102px' },
          paddingRight: '4px',
          height: '100%',
        }}
      >
        <Container sx={{ height: '100%' }}>
          <Toolbar disableGutters sx={{ height: '100%' }}>
            <Link href="/" passHref style={{ display: 'flex' }}>

              <Box
                component="img"
                src={`${process.env.NEXT_PUBLIC_URL}/images/LogoImage.svg`}
                alt="Logo"
                sx={{
                  width: { xs: '140px', lg: '168px' },
                  cursor: 'pointer',
                }}
              />

            </Link>
            <Box sx={{ width: '100%', display: { xs: 'flex', lg: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{ mr: '12px', ml: 'auto' }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', lg: 'none' },
                }}
              >
                {[
                  ...pages,
                  {
                    name: 'Mina sidor',
                    link: 'https://minasidor.privatmaklaren.se',
                  },
                ].map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={handleCloseNavMenu}
                    sx={{ '&:hover': { backgroundColor: 'white' } }}
                  >
                    <Link href={page.link} passHref legacyBehavior>
                      <Typography
                        textAlign="center"
                        component="a"
                        sx={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {page.name === 'Till Salu' ? 'Till salu' : page.name}
                      </Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                paddingTop: '6px',
                paddingLeft: '67px',
                flexGrow: 1,
                display: { xs: 'none', lg: 'flex' },
              }}
            >
              {pages.map((page, i) => {
                return (
                  <Link href={page.link} key={page.name} passHref legacyBehavior>
                    <Button
                      onClick={handleCloseNavMenu}
                      sx={{
                        paddingY: '15px',
                        paddingX: '32px',
                        color: 'white',
                        display: 'block',
                      }}
                    >
                      <p
                        className="font-Avenir-Regular"
                        style={{
                          textAlign: 'center',
                          fontSize: '20px',
                          margin: '0px',
                          borderBottom:
                            i === markPos ? '2px solid white' : '0px',
                        }}
                      >
                        {page.name}
                      </p>
                    </Button>
                  </Link>
                );
              })}
            </Box>
            <Link
              href="https://minasidor.privatmaklaren.se"
              passHref
              scroll={false}
              legacyBehavior>
              <Box
                component="a"
                sx={{
                  cursor: 'pointer',
                  display: { xs: 'none', lg: 'flex' },
                  my: 'auto',
                  marginLeft: 'auto',
                  marginRight: '80px',
                  borderRadius: '50px',
                  height: '34px',
                  width: '146px',
                  bgcolor: '#F1F1F1',
                  color: 'black',
                  paddingLeft: '10px',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <AccountCircleOutlinedIcon
                  sx={{ my: 'auto', mr: '5px', fontSize: '25px' }}
                />
                <p
                  className="font-Lato-Regular my-auto"
                  style={{ fontSize: '17px' }}
                >
                  Mina sidor
                </p>
              </Box>
            </Link>
          </Toolbar>
        </Container>
      </Box>
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    associationTypes: state.main.associationTypes,

    municipalities: state.main.municipalities,

    houseTypes: state.main.houseTypes,

    biddingInfo: state.main.biddingInfo,
  };
};

export default compose(withRouter, connect(mapStateToProps, null))(Navbar);
